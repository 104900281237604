import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/lithium-ion-cell-and-battery-pack/1(2).jpg"
import img2 from "../../images/lithium-ion-cell-and-battery-pack/1(4).jpg"
import img3 from "../../images/lithium-ion-cell-and-battery-pack/2(1).jpg"
import img4 from "../../images/lithium-ion-cell-and-battery-pack/3(1).jpg"

import descImg1 from "../../images/lithium-ion-cell-and-battery-pack/description.png"
import descImg2 from "../../images/lithium-ion-cell-and-battery-pack/advantage.png"
import descImg3 from "../../images/lithium-ion-cell-and-battery-pack/hsl-advantage.png"
import descImg4 from "../../images/lithium-ion-cell-and-battery-pack/application.png"

const LithiumIonCellAndBatteryPackPage = () => {
  return (
    <Layout home={false}>
      <SEO title="Lithium Cell and Lithium Ion Battery Packs" />
      <ProductPage
        productTitle="Lithium Cell and Lithium Ion Battery Packs"
        showcase={
          <>
            <p style={{ lineHeight: "1.7rem" }}>
              High street lighting battery packs are a one-stop solution for
              catering to all your Lithium battery needs. We focus on providing
              eco-friendly lithium battery packs thus successfully achieving
              promised higher capacities. These are lightweight & can be
              customized to suit your requirements. We manufacture MNRE
              recommended & BIS approved lithium ion battery packs in India to
              be used for various applications like portable devices, solar
              street lights, UPS & inverters.
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1, img2, img3, img4]}
        description={
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg1}
                style={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  maxHeight: "40rem",
                }}
              />
            </div>
            <div className="product-description-heading">Technological Applications</div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg2}
                style={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  maxHeight: "50rem",
                }}
              />
            </div>
            <div className="product-description-heading">HSL Advantage</div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg3}
                style={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  maxHeight: "35rem",
                }}
              />
            </div>
            <div className="product-description-heading">Applications</div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg4}
                style={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  maxHeight: "12rem",
                }}
              />
            </div>
          </div>
        }
      />
    </Layout>
  )
}

export default LithiumIonCellAndBatteryPackPage
